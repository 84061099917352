import React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'

import SEO from '../components/seo'
import Layout from '../components/layout'
import Slices from '../components/slices'

const Post = (props) => {
	const postContent = props.data.prismic.allPosts.edges[0].node

	return (
		<Layout>
			<SEO 
				title={postContent.meta_title[0].text}
				description={postContent.meta_description[0].text}
				image={postContent.cover_image.meta} />
			<div className="c-title">
				<RichText render={postContent.page_title} />
			</div>
			<Slices body={postContent.body} />
		</Layout>
	)
}

export default Post

export const query = graphql`
	query PostQuery($id: String) {
		prismic {
			allPosts(id: $id) {
				edges {
					node {
						page_title
						meta_title
						meta_description
						cover_image
						_meta {
							id
							uid
						}
						body {
							... on PRISMIC_PostBodyParagraph {
								type
								primary {
									paragraph_text
								}
							}
							... on PRISMIC_PostBodyImage {
								type
								primary {
									size
									image
								}
							}
							... on PRISMIC_PostBodyVideo {
								type
								primary {
									video
								}
							}
						}
					}
				}
			}
		}
	}
`
